import './importcontacts.css'

import React, {useState, useEffect, useRef} from 'react'
import {styled} from '@mui/material/styles'

import Dialog from '@mui/material/Dialog'
import Tooltip from '@mui/material/Tooltip'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import {Grid, TextField} from '@mui/material'
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete'
import axios from '../../../../helpers/request'
import leftIcon from '../../../images/contactlefticon.svg'
import rightIcon from '../../../images/contactrighticon.svg'
import {useAppState} from '../../../../state'
import Button from '@mui/material/Button'

import Swal from 'sweetalert2'

const PHONE_NUMBER_REGEX = new RegExp(/^\+?\d+$/)
const EMAIL_REGEX = new RegExp(/^\S+@\S+\.\S+$/)

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minwidth: '400px'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2)
  }
}))

const tableColumnLabels = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  origin: "Origin",
  destination: "Destination",
  saleschannel: "Sales Channel",
  bookinglocation: "Booking Location",
  rsid: "RSID",
  servicedate: "Service Date",
  servicetime: "Service Time",
  serviceorigin: "Service Origin",
  servicedestination: "Service Destination",
  orderdate: "Order Date",
  bookingdeptime: "Booking Dep Time",
  orderid: "Order ID",
  ticketcode: "Ticket Code",
  accomtype: "Accom Type",
  number: "Phone Number",
}

const filter = createFilterOptions()

export default function MappingColumns(props) {
  let defaultColumnNeedUpdate = {headerKeyName: '', headerKeyIndex: ''}
  let tableDivEle = useRef()
  let tableMainDivEle = useRef()
  const [open, setOpen] = useState(false)
  const [openSaveDbModal, setOpenSaveDbModal] = useState(false)
  const [isAllColumnsMatched, setisAllColumnsMatched] = useState(false)
  const [contactColumnsData, setContactColumnsData] = useState([])
  const [columnSelected, setColumnSelected] = useState('')
  const [modalColumns, setModalColumns] = useState([])
  const [columnNeedUpdate, setColumnNeedUpdate] = useState(defaultColumnNeedUpdate)
  const [saveContacts, setSaveContacts] = useState('no')
  const [newListName, setNewListName] = useState('')
  const [newListParentName, setNewListParentName] = useState('')
  const {user: loggedUser} = useAppState()
  const [parentList, setParentList] = useState(false)

  const [openReviewContactModal, setOpenReviewContactModal] = useState(false)
  const [validContacts, setValidContacts] = useState([])
  const [inValidContacts, setInValidContacts] = useState([])

  const companyId = loggedUser.added_by ? loggedUser.added_by : loggedUser.id
  let tableRowData = props.browseFileData
  let headers = []
  let firstThreeRowData = []
  let minTableLength = props.componentCalledSource === 'contactList' ? 6 : 4

  if (tableRowData.length) {
    headers = Object.keys(tableRowData[0])
    firstThreeRowData = [tableRowData[0], tableRowData[1], tableRowData[2]]
  }

  const getParentTypes = () => {
    let type = ''
    if (props.componentCalledSource === 'contactList') {
      type = props.currentListType
    } else {
      if (props.componentCalledSource === 'sms_emailChannel') {
        type = 'sms_email'
      } else if (props.componentCalledSource === 'smsChannel') {
        type = 'sms'
      } else if (props.componentCalledSource === 'emailChannel') {
        type = 'email'
      }
    }

    let url = `/get-contactlist-parents/all?type=${type}`
    if (loggedUser.role !== 'superadmin') {
      url = `/get-contactlist-parents/${companyId}?type=${type}`
    }
    axios
      .get(url)
      .then(res => {
        if (res.data) {
          // console.log(res.data);
          setParentList(res.data)
        }
      })
      .catch(err => {
        console.error('errr', err)
      })
  }

  const checkedAllColumnsMatched = () => {
    let firstRow = tableRowData[0]
    let allColumns = modalColumns.length
    let checkedColumns = modalColumns.filter(item => {
      let forcedUnchecked = firstRow.hasOwnProperty(item.name + '_forcedUnchecked')
        ? firstRow[item.name + '_forcedUnchecked']
        : ''
      let columnIsChecked = item.isChecked && !forcedUnchecked
      return columnIsChecked
    })
    setisAllColumnsMatched(allColumns === checkedColumns.length ? true : false)
  }

  //To open modal while click on table header
  const openEditcolumnModal = (headerKey, index) => {
    setColumnNeedUpdate({headerKeyName: headerKey, headerKeyIndex: index})
    setOpen(true)
  }

  // To update column header as per selected option
  const handleEditcolumnSubmit = () => {
    // console.log("columnNeedUpdate", columnNeedUpdate);
    // console.log("columnSelected", columnSelected);
    props.updateDataColumn(columnNeedUpdate, columnSelected)
    setOpen(false)
    setColumnSelected('')
    setColumnNeedUpdate(defaultColumnNeedUpdate)
  }

  const handleCancelAction = () => {
    setOpen(false)
    setColumnSelected('')
    setColumnNeedUpdate(defaultColumnNeedUpdate)
  }

  const removeSelectedColumn = (headerKey, index, event) => {
    let tempContactColumnsData = contactColumnsData
    tempContactColumnsData[headerKey] = false
    setContactColumnsData(tempContactColumnsData)

    let tempModalColumns = modalColumns.map(data => {
      if (data.name === headerKey) {
        data.isChecked = false
        data.forcedUnchecked = true
      }
      return data
    })
    setModalColumns(tempModalColumns)
    checkedAllColumnsMatched()

    tableRowData.map(data => {
      data[headerKey + '_forcedUnchecked'] = !event.target.checked
    })
    props.setBrowseFileData(tableRowData)
  }

  const rowDataDisplay = row => {
    let returnHtml = ''
    for (const property in row) {
      let isUncheckedColumn = property.includes('_forcedUnchecked')
      if (!isUncheckedColumn) {
        let isforcedUnchecked = row.hasOwnProperty(property + '_forcedUnchecked') ? row[property + '_forcedUnchecked'] : ''
        let tdClassName =
          contactColumnsData[property] && !isforcedUnchecked ? 'selected-column-data-style' : 'unrecognized-column-data-style'
        returnHtml += `<td class=${tdClassName}> ${row[property]}`
      }
    }
    return returnHtml
  }

  const saveContactAction = () => {
    if (saveContacts == 'yes' && newListName === '') {
      Swal.fire({title: 'list name is required to save contacts', confirmButtonColor: '#3085d6'})
      return false
    }
    let tempParentName = newListParentName
    if (newListParentName !== '' && newListParentName !== null) {
      tempParentName = tempParentName.parent_type
    }
    let matchedColumns = modalColumns.filter(col => col.isChecked)
    matchedColumns.length > 0
      ? props.continueHandler(saveContacts, newListName, tempParentName)
      : Swal.fire({title: 'Min 1 columns needs to be matched', confirmButtonColor: '#3085d6'})
  }

  useEffect(() => {
    if (props.componentCalledSource !== 'contactList') {
      props.setUpdateModalSize(true)
    }
    getContactsColumns()
  }, [props.browseFileData])

  useEffect(() => {
    if (tableRowData.length) {
      checkedAllColumnsMatched()
    }
    getParentTypes()
  }, [modalColumns])

  // Get table colms from db to display while edit table header
  const getContactsColumns = () => {
    axios
      .get(`/get-contact-table-columns`)
      .then(res => {
        let allColumns = res.data
        let saveDataColumns = []

        // console.log("columns::", Object.keys(tableColumnLabels));
        allColumns.filter((row, index) => {
          // if (Object.keys(tableColumnLabels).includes(row.Field)) {
          //     saveDataColumns.push(row.Field);
          // }
          if (
            row.Field !== 'id' &&
            row.Field !== 'listId' &&
            row.Field !== 'companyId' &&
            row.Field !== 'type' &&
            row.Field !== 'createdBy'
          ) {
            saveDataColumns.push(row.Field)
          }
        })

        let availableColumns = []
        let modalColumnList = []
        let haveAllColumns = modalColumns.length === saveDataColumns.length

        allColumns = allColumns.filter((row, index) => {
          // if (Object.keys(tableColumnLabels).includes(row.Field)) {
          if (
            row.Field !== 'id' &&
            row.Field !== 'listId' &&
            row.Field !== 'companyId' &&
            row.Field !== 'type' &&
            row.Field !== 'createdBy'
          ) {
            let colname = row.Field
            let isChecked = headers.includes(colname)
            let forcedUnchecked = false
            let columnState = modalColumns.filter(item => {
              return item.name === row.Field ? item : null
            })
            columnState = columnState.length ? columnState[0] : []

            if (columnState.length) {
              isChecked = isChecked && !columnState.forcedUnchecked
              forcedUnchecked = columnState.forcedUnchecked
            }

            availableColumns[colname] = isChecked
            if (!columnState.length) {
              modalColumnList.push({
                index: index,
                name: row.Field,
                isChecked: isChecked,
                forcedUnchecked: forcedUnchecked
              })
            }
          }
        })

        if (modalColumnList.length) {
          setModalColumns(modalColumnList)
        }
        setContactColumnsData(availableColumns)
        checkedAllColumnsMatched()
      })
      .catch(err => {
        console.error('errr', err)
      })
  }

  const reviewContacts = source => {
    let fileData = props.browseFileData
    let validRowData = props.browseFileData
    let invalidRowData = []

    if (source === 'sms_emailChannel' || source === 'smsChannel') {
      let invalidPhoneNumberData = fileData.filter(row => {
        const invalidData = !PHONE_NUMBER_REGEX.test(row['number'])
        return invalidData === true
      })
      let validPhoneNumberData = fileData.filter(row => {
        const invalidData = !PHONE_NUMBER_REGEX.test(row['number'])
        return invalidData === false
      })
      validRowData = [...validPhoneNumberData]
      invalidRowData = [...invalidPhoneNumberData]
    }

    // console.log("validRowData-1", validRowData);
    // console.log("invalidRowData-1", invalidRowData);

    if (source === 'sms_emailChannel' || source === 'emailChannel') {
      let invalidEmailData = validRowData.filter(row => {
        const invalidData = !EMAIL_REGEX.test(row['email'])
        return invalidData === true
      })
      let validEmailData = validRowData.filter(row => {
        const invalidData = !EMAIL_REGEX.test(row['email'])
        return invalidData === false
      })
      validRowData = [...validEmailData]
      invalidRowData = [...invalidRowData, ...invalidEmailData]
    }

    // console.log("validRowData-2", validRowData);
    // console.log("invalidRowData-2", invalidRowData);

    setValidContacts(validRowData)
    setInValidContacts(invalidRowData)

    if (invalidRowData.length) {
      setOpenReviewContactModal(true)
    } else {
      if (props.componentCalledSource === 'contactList') {
        props.continueHandler('yes', '', '', validRowData)
      } else {
        if (props.enable_contacts) {
          setOpenSaveDbModal(true)
        } else {
          props.continueHandler(saveContacts, newListName, '', validRowData)
        }
      }
    }
  }

  const handleSubmit = () => {
    let matchedColumns = modalColumns.filter(col => col.isChecked)

    // let matchedColumnNames = modalColumns.map((col) => (col.isChecked) ? col.name : null);
    const matchedColumnNames = modalColumns.map(item => {
      let firstRow = tableRowData[0]
      let forcedUnchecked = firstRow.hasOwnProperty(item.name + '_forcedUnchecked')
        ? firstRow[item.name + '_forcedUnchecked']
        : ''
      if (item.isChecked && !forcedUnchecked) {
        return item.name
      }
    })
    // console.log("matchedColumnNames", matchedColumnNames)

    /*if (props.componentCalledSource === "contactList") {
            props.continueHandler();
        } else {
            //temp setting default save contact list
            setOpenSaveDbModal(true);
            //props.continueHandler('yes', "list-"+ Math.floor(Math.random() * 100));
        }*/

    if (props.componentCalledSource === 'contactList') {
      let minColumnsMatched =
        matchedColumnNames.includes('first_name') &&
        matchedColumnNames.includes('last_name') &&
        (matchedColumnNames.includes('number') || matchedColumnNames.includes('email'))

      // let minColumnsMatched = matchedColumnNames.includes("number") || matchedColumnNames.includes("email");
      // (minColumnsMatched) ? props.continueHandler() : alert("Min and email/number columns needs to be matched");
      // props.continueHandler();
      if (minColumnsMatched) {
        if (props.currentListType == 'sms_email') {
          if (matchedColumnNames.includes('number') && matchedColumnNames.includes('email')) {
            reviewContacts('sms_emailChannel')
          } else {
            Swal.fire({title: 'email and number columns should be present for this list', confirmButtonColor: '#3085d6'})
            return
          }
        } else if (props.currentListType == 'sms') {
          if (matchedColumnNames.includes('number')) {
            reviewContacts('smsChannel')
          } else {
            Swal.fire({title: 'number columns should be present for this list', confirmButtonColor: '#3085d6'})
            return
          }
        } else if (props.currentListType == 'email') {
          if (matchedColumnNames.includes('email')) {
            reviewContacts('emailChannel')
          } else {
            Swal.fire({title: 'email columns should be present for this list', confirmButtonColor: '#3085d6'})
            return
          }
        }
        // props.continueHandler()
      } else {
        Swal.fire({
          title: 'first_name, last_name and email/number columns should be present for import contact',
          confirmButtonColor: '#3085d6'
        })
        return
      }
    } else {
      /*let isCommonColumnsMatched = matchedColumnNames.includes("first_name") &&
                //matchedColumnNames.includes("last_name") &&
                matchedColumnNames.includes("address") &&
                matchedColumnNames.includes("pin") &&
                matchedColumnNames.includes("expiry") &&
                matchedColumnNames.includes("reference") &&
                matchedColumnNames.includes("uid");
            let finalMatchFlag = false;
            let finalMsg = "";

            if (props.componentCalledSource === "sms_emailChannel") {
                finalMatchFlag = isCommonColumnsMatched && 
                    matchedColumnNames.includes("number") &&
                    matchedColumnNames.includes("email");
                finalMsg = "All columns needs to be matched";
            } else if (props.componentCalledSource === "smsChannel") {
                finalMatchFlag = isCommonColumnsMatched && 
                    matchedColumnNames.includes("number");
                finalMsg = "All columns needs to be matched except email";
            } else if (props.componentCalledSource === "emailChannel") {
                finalMatchFlag = isCommonColumnsMatched &&
                    matchedColumnNames.includes("email");
                finalMsg = "All columns needs to be matched except number";
            }
            (finalMatchFlag) ?
                setOpenSaveDbModal(true) :
                alert(finalMsg);*/

      // setOpenSaveDbModal(true);

      let source = props.componentCalledSource

      if (source === 'sms_emailChannel') {
        if (matchedColumnNames.includes('number') && matchedColumnNames.includes('email')) {
          reviewContacts(source)
        } else {
          Swal.fire({title: 'email and number columns should be present', confirmButtonColor: '#3085d6'})
          return
        }
      } else if (source === 'smsChannel') {
        if (matchedColumnNames.includes('number')) {
          reviewContacts(source)
        } else {
          Swal.fire({title: 'number columns should be present', confirmButtonColor: '#3085d6'})
          return
        }
      } else if (source === 'emailChannel') {
        if (matchedColumnNames.includes('email')) {
          reviewContacts(source)
        } else {
          Swal.fire({title: 'email columns should be present', confirmButtonColor: '#3085d6'})
          return
        }
      }
    }
  }

  const objectToCsv = function (data) {
    const datacolumns = headers.filter(item => !item.includes('_forcedUnchecked'))

    const csvRows = []

    /* Using push() method we push fetched
           data into csvRows[] array */
    csvRows.push(datacolumns.join(','))

    // Loop to get value of each objects key
    for (const row of data) {
      const values = datacolumns.map(header => {
        return row[header]
      })

      // To add, separator between each value
      csvRows.push(values.join(','))
    }

    /* To add new line for each objects values
           and this return statement array csvRows
           to this function.*/
    return csvRows.join('\n')
  }

  const download = function (data) {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], {type: 'text/csv'})
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)
    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')
    // Passing the blob downloading url
    a.setAttribute('href', url)
    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', 'invalid_contact_' + new Date().getTime() + '.csv')
    // Performing a download with click
    a.click()
  }

  const downloadInvalidData = () => {
    const content = objectToCsv(inValidContacts)
    download(content)
  }

  const continueWithValid = () => {
    if (props.componentCalledSource === 'contactList') {
      props.continueHandler('yes', '', '', validContacts)
    } else {
      if (props.enable_contacts) {
        props.setBrowseFileData(validContacts)
        setOpenSaveDbModal(true)
      } else {
        props.continueHandler(saveContacts, newListName, '', validContacts)
      }
    }
  }

  return (
    <>
      <span className="import-contact-header">Match column labels to contact information</span>

      <Grid container className="mt-20 mappingColum-grid">
        <Grid item xs={12} md={12} className="fileDataTable">
          <span className="import-contact-p">{tableRowData.length} contacts were recognized in this file</span>
        </Grid>

        <Grid item xs={12} md={12} lg={12} className="fileDataTable match-table-container mt-20" ref={tableMainDivEle}>
          <table
            className="mappingColumn-match-table"
            aria-label="custom pagination table match-table"
            border="1"
            ref={tableDivEle}
          >
            <thead>
              <tr>
                {headers.map((headerKey, index) => {
                  let isUncheckedColumn = headerKey.includes('_forcedUnchecked')
                  if (!isUncheckedColumn) {
                    let isChecked = contactColumnsData[headerKey]
                    let firstRow = tableRowData[0]
                    let forcedUnchecked = firstRow.hasOwnProperty(headerKey + '_forcedUnchecked')
                      ? firstRow[headerKey + '_forcedUnchecked']
                      : ''
                    let columnIsChecked = isChecked && !forcedUnchecked
                    return (
                      <th className={columnIsChecked ? 'selected-column-header-style' : 'unrecognized-column'}>
                        <div className="column-header-box">
                          <div className="column-header-name-box" onClick={() => openEditcolumnModal(headerKey, index)}>
                            {columnIsChecked && (
                              <>
                                <BorderColorIcon sx={{color: '#005CC8'}} /> &nbsp;&nbsp;
                              </>
                            )}
                            {tableColumnLabels[headerKey] ? tableColumnLabels[headerKey] : headerKey}
                          </div>
                          {columnIsChecked ? (
                            <div className="column-header-name-checkbox">
                              <Tooltip title="Column is matched, click here to remove matched" placement="top-start">
                                <input
                                  className="col-cbox"
                                  type="checkbox"
                                  defaultChecked={columnIsChecked}
                                  onClick={evt =>
                                    columnIsChecked
                                      ? removeSelectedColumn(headerKey, index, evt)
                                      : openEditcolumnModal(headerKey, index)
                                  }
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            <div className="column-header-name-checkbox" onClick={evt => openEditcolumnModal(headerKey, index)}>
                              <Tooltip title="Column don't matched, click to match column" placement="top-start">
                                <ErrorOutlineIcon sx={{color: '#fff'}} />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </th>
                    )
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {firstThreeRowData.map((item, index) => (
                <tr className="content" dangerouslySetInnerHTML={{__html: rowDataDisplay(item)}}></tr>
              ))}
            </tbody>
          </table>
        </Grid>

        {headers.filter(item => !item.includes('_forcedUnchecked')).length > minTableLength && (
          <Grid item xs={12} md={12} className="fileDataTable scroll-button-div">
            <div className="scroll-button-box no-scroll-table">
              <img
                src={leftIcon}
                title="left-icon"
                className="left-s-icon"
                onClick={() => (tableMainDivEle.current.scrollLeft -= 600)}
              />
            </div>
            <div className="scroll-button-box have-scroll-table ml-20">
              <img
                src={rightIcon}
                title="right-icon"
                className="right-s-icon"
                onClick={() => (tableMainDivEle.current.scrollLeft += 600)}
              />
            </div>
          </Grid>
        )}

        <Grid item xs={12} md={12} className="fileDataTable mt-20">
          <span className="import-contact-p">
            {
              modalColumns.filter(item => {
                let firstRow = tableRowData[0]
                let forcedUnchecked = firstRow.hasOwnProperty(item.name + '_forcedUnchecked')
                  ? firstRow[item.name + '_forcedUnchecked']
                  : ''
                if (item.isChecked && !forcedUnchecked) {
                  return item
                }
              }).length
            }
            &nbsp;matched columns will be import in contacts out of&nbsp;
            {headers.filter(item => !item.includes('_forcedUnchecked')).length}&nbsp;columns in file
          </span>
        </Grid>

        <Grid item xs={12} md={12}>
          <button onClick={handleSubmit} className="btn btn-primary text-white br-10 importsubmit">
            Complete Import
          </button>

          <button onClick={() => props.handleBackClick()} className="btn btn-white-red br-10 importsubmit ml-20">
            Back
          </button>
        </Grid>
      </Grid>

      {/* Modals for process */}
      {!isAllColumnsMatched && (
        <BootstrapDialog
          onClose={handleCancelAction}
          aria-labelledby="customized-dialog-title-1"
          open={open}
          className="editcolumnModal"
        >
          <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title" className="dialogTitle-component">
            Edit Column Label
            <IconButton
              aria-label="close"
              onClick={handleCancelAction}
              sx={{
                float: 'right',
                padding: '0px',
                color: theme => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <label>Select a field for this column</label>
            <select
              name="contact"
              className="popupDropdown"
              defaultValue={columnSelected}
              onChange={e => setColumnSelected(e.target.value)}
            >
              <option disabled selected value="">
                Select contact type
              </option>
              {modalColumns.map(col => {
                let isChecked = col.isChecked
                let firstRow = tableRowData[0]
                let forcedUnchecked = firstRow.hasOwnProperty(col.name + '_forcedUnchecked')
                  ? firstRow[col.name + '_forcedUnchecked']
                  : ''
                let columnIsChecked = isChecked && !forcedUnchecked
                return (
                  <option key={col.index} value={col.name} disabled={columnIsChecked}>
                    {tableColumnLabels[col.name] ? tableColumnLabels[col.name] : col.name} {columnIsChecked ? '- matched' : ''}
                  </option>
                )
              })}
            </select>
          </DialogContent>
          <DialogActions>
            <button onClick={handleEditcolumnSubmit} className="btn btn-primary text-white br-10">
              Save changes
            </button>
            <button onClick={handleCancelAction} className="btn btn-white-red br-10 ml-20">
              Cancel
            </button>
          </DialogActions>
        </BootstrapDialog>
      )}

      {isAllColumnsMatched && (
        <BootstrapDialog
          onClose={handleCancelAction}
          aria-labelledby="customized-dialog-title-1"
          open={open}
          className="editcolumnModal"
        >
          <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title" className="editcolumnModal22">
            All Columns Matched
          </DialogTitle>
          <DialogContent dividers>
            <label>
              All columns has been matched, others extra data will be ignored. If you think have make mistake then uncheck column
              and match with other column.
            </label>
          </DialogContent>
          <DialogActions>
            <button onClick={handleCancelAction} className="btn btn-primary text-white br-10">
              Okay
            </button>
          </DialogActions>
        </BootstrapDialog>
      )}

      {props.componentCalledSource !== 'contactList' && (
        <BootstrapDialog
          onClose={() => setOpenSaveDbModal(false)}
          aria-labelledby="customized-dialog-title-1"
          open={openSaveDbModal}
          className="editcolumnModal"
        >
          <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title" className="editcolumnModal22">
            Save Contacts
          </DialogTitle>
          <DialogContent dividers>
            <div className="">
              <h6>Do you like to save all this contacts?</h6>
              <input
                type="radio"
                name="saveContacts"
                checked={saveContacts === 'yes' ? 'checked' : ''}
                defaultValue="yes"
                onClick={() => setSaveContacts('yes')}
              />
              <span className="ml-5">Yes</span>
              <input
                type="radio"
                className="ml-10"
                name="saveContacts"
                checked={saveContacts === 'no' ? 'checked' : ''}
                defaultValue="no"
                onClick={() => setSaveContacts('no')}
              />
              <span className="ml-5">No</span>
            </div>
            {saveContacts === 'yes' && (
              <>
                <div className="sendNowItem mb-0">
                  <label>List name</label>
                  <input
                    type="text"
                    name="newListName"
                    placeholder="Enter name for this new contact list"
                    onChange={e => setNewListName(e.target.value)}
                  />
                </div>
                <div className="sendNowItem mb-0">
                  <label>Parent type of list</label>
                  <Autocomplete
                    value={newListParentName}
                    onChange={(event, newValue) => {
                      setNewListParentName(newValue)
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params)

                      const {inputValue} = params
                      // Suggest the creation of a new value
                      const isExisting = options.some(option => inputValue === option.parent_type)
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          parent_type: `Add "${inputValue}"`
                        })
                      }

                      return filtered
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={parentList}
                    getOptionLabel={option => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue
                      }
                      // Regular option
                      return option.parent_type
                    }}
                    renderOption={(props, option) => <li {...props}>{option.parent_type}</li>}
                    sx={{width: '100%'}}
                    freeSolo
                    renderInput={params => <TextField {...params} />}
                  />
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <button onClick={() => saveContactAction()} className="btn btn-primary text-white br-10">
              Submit
            </button>
            <button onClick={() => setOpenSaveDbModal(false)} className="btn btn-white-red br-10 ml-20 mr-0">
              Cancel
            </button>
          </DialogActions>
        </BootstrapDialog>
      )}
      {/* Modals for process */}

      <BootstrapDialog
        onClose={() => setOpenReviewContactModal(false)}
        aria-labelledby="customized-dialog-title-1"
        open={openReviewContactModal}
        className="editcolumnModal"
      >
        <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title" className="dialogTitle-component">
          Review Contacts
          <IconButton
            aria-label="close"
            onClick={() => setOpenReviewContactModal(false)}
            sx={{
              float: 'right',
              padding: '0px',
              color: theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <p>Recognized {props.browseFileData.length} contacts from file, </p>
                    <p>Out of them {validContacts.length} are valid and {inValidContacts.length} are invalid.</p>
                    <p>You can continue with valid entries OR can download invalid entires <Button onClick={() => downloadInvalidData()}>here</Button> and upload file again with correct data.</p> */}
          <p>
            {props.browseFileData.length} contacts have been successfully imported from your file. However, there were{' '}
            {inValidContacts.length} invalid entries. You can download the list of invalid entries{' '}
            <Button className="invalidList" onClick={() => downloadInvalidData()}>
              here
            </Button>
            .
          </p>
        </DialogContent>
        <DialogActions>
          <button
            onClick={validContacts.length === 0 ? () => {} : () => continueWithValid()}
            className="btn btn-primary text-white br-10"
            disabled={validContacts.length === 0}
          >
            Continue
          </button>
          <button
            onClick={() => {
              setOpenReviewContactModal(false)
            }}
            className="btn btn-white-red br-10 ml-20"
          >
            Back
          </button>
        </DialogActions>
      </BootstrapDialog>
    </>
  )
}
