import '../../NewContact/newcontact.css'
import React, {useState, useEffect} from 'react'
import {Link, useHistory, useParams, useLocation} from 'react-router-dom'
import axios from '../../../../helpers/request'

import {useAppState} from '../../../../state'

import {DeleteOutlined, EditOutlined} from '@material-ui/icons'
import {DataGrid} from '@material-ui/data-grid'
import {Breadcrumbs, Typography} from '@material-ui/core'

import Swal from 'sweetalert2'

export default function CreateContactList(props) {
  const [listInfo, setListInfo] = useState({
    name: '',
    type: ''
  })
  const [tabValue, setTabValue] = useState(0)
  const [list, setList] = useState([])

  const params = useParams()

  const location = useLocation()
  const isViewable = location.pathname.split('/').includes('view-contact-list')
  const isEditable = location.pathname.split('/').includes('edit-contact-list')

  const {user: loggedUser} = useAppState()

  const history = useHistory()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if ((isEditable || isViewable) && params.listId) {
      getListDetails()
      getContactList()
    }
  }, [])

  const getListDetails = () => {
    axios
      .get(`/contact-list/${params.listId}?role=` + loggedUser.role)
      .then(res => {
        if (res.data && res.data.listId) {
          console.log('res.data', res.data)
          setListInfo(res.data)
        }
      })
      .catch(err => {
        console.error('errr', err)
      })
  }

  const getContactList = () => {
    axios
      .get(`/contact/list/${params.listId}`)
      .then(res => {
        if (res.status == 200 && res.data.length) {
          console.log('getContactList', res.data)
          setList(res.data)
        }
      })
      .catch(err => {
        console.error('errr', err)
      })
  }

  const handleDelete = async (id, label) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${label}`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    })

    if (result.isConfirmed) {
      axios
        .delete('/contact/' + id)
        .then(function (response) {
          getContactList()
          Swal.fire({title: 'Contact deleted successfully', confirmButtonColor: '#3085d6'})
        })
        .catch(function (error) {
          Swal.fire({title: error?.response?.data?.message || 'Unable to delete contact', confirmButtonColor: '#3085d6'})
        })
    }
  }

  let contactColumns = []
  switch (listInfo.type) {
    case 'email':
      contactColumns = [
        {field: 'id', headerName: 'ID', width: 200},
        {field: 'first_name', headerName: 'First Name', width: 400},
        {field: 'last_name', headerName: 'Last Name', width: 400},
        {field: 'email', headerName: 'Email', width: 400},
        {field: 'origin', headerName: 'Origin', width: 250},
        {field: 'destination', headerName: 'Destination', width: 250},
        {field: 'saleschannel', headerName: 'Sales Channel', width: 250},
        {field: 'bookinglocation', headerName: 'Booking Location', width: 250},
        {field: 'rsid', headerName: 'RSID', width: 250},
        {field: 'servicedate', headerName: 'Service Date', width: 250},
        {field: 'servicetime', headerName: 'Service Time', width: 250},
        {field: 'serviceorigin', headerName: 'Service Origin', width: 250},
        {field: 'servicedestination', headerName: 'Service Destination', width: 250},
        {field: 'orderdate', headerName: 'Order Date', width: 250},
        {field: 'bookingdeptime', headerName: 'Booking Dep Time', width: 250},
        {field: 'orderid', headerName: 'Order ID', width: 250},
        {field: 'ticketcode', headerName: 'Ticket Code', width: 250},
        {field: 'accomtype', headerName: 'Accom Type', width: 250},
        {
          field: 'action',
          headerName: 'Action',
          width: 330,
          renderCell: ({row}) => {
            return (
              <div className="userListActions">
                <Link
                  to={{
                    pathname: '/contact-edit/' + row.id,
                    state: {listId: params.listId}
                  }}
                >
                  <button className="actionElementButton">
                    <EditOutlined className="actionElementIcon" />
                    {/* <span className="actionButtonText">Edit</span> */}
                  </button>
                </Link>
                <DeleteOutlined
                  className="userListDelete"
                  onClick={() => handleDelete(row.id, row.first_name + ' ' + row.last_name)}
                />
              </div>
            )
          }
        }
      ]
      break
    case 'sms_email':
      contactColumns = [
        {field: 'id', headerName: 'ID', width: 100},
        {field: 'first_name', headerName: 'First Name', width: 400},
        {field: 'last_name', headerName: 'Last Name', width: 400},
        {field: 'number', headerName: 'Number', width: 300},
        {field: 'email', headerName: 'Email', width: 300},
        {field: 'origin', headerName: 'Origin', width: 250},
        {field: 'destination', headerName: 'Destination', width: 250},
        {field: 'saleschannel', headerName: 'Sales Channel', width: 250},
        {field: 'bookinglocation', headerName: 'Booking Location', width: 250},
        {field: 'rsid', headerName: 'RSID', width: 250},
        {field: 'servicedate', headerName: 'Service Date', width: 250},
        {field: 'servicetime', headerName: 'Service Time', width: 250},
        {field: 'serviceorigin', headerName: 'Service Origin', width: 250},
        {field: 'servicedestination', headerName: 'Service Destination', width: 250},
        {field: 'orderdate', headerName: 'Order Date', width: 250},
        {field: 'bookingdeptime', headerName: 'Booking Dep Time', width: 250},
        {field: 'orderid', headerName: 'Order ID', width: 250},
        {field: 'ticketcode', headerName: 'Ticket Code', width: 250},
        {field: 'accomtype', headerName: 'Accom Type', width: 250},
        {
          field: 'action',
          headerName: 'Action',
          width: 200,
          renderCell: ({row}) => {
            return (
              <div className="userListActions">
                <Link
                  to={{
                    pathname: '/contact-edit/' + row.id,
                    state: {listId: params.listId}
                  }}
                >
                  <button className="actionElementButton">
                    <EditOutlined className="actionElementIcon" />
                    {/* <span className="actionButtonText">Edit</span> */}
                  </button>
                </Link>
                <DeleteOutlined
                  className="userListDelete"
                  onClick={() => handleDelete(row.id, row.first_name + ' ' + row.last_name)}
                />
              </div>
            )
          }
        }
      ]
      break
    default:
      contactColumns = [
        {field: 'id', headerName: 'ID', width: 200},
        {field: 'first_name', headerName: 'First Name', width: 400},
        {field: 'last_name', headerName: 'Last Name', width: 400},
        {field: 'number', headerName: 'Number', width: 400},
        {field: 'origin', headerName: 'Origin', width: 250},
        {field: 'destination', headerName: 'Destination', width: 250},
        {field: 'saleschannel', headerName: 'Sales Channel', width: 250},
        {field: 'bookinglocation', headerName: 'Booking Location', width: 250},
        {field: 'rsid', headerName: 'RSID', width: 250},
        {field: 'servicedate', headerName: 'Service Date', width: 250},
        {field: 'servicetime', headerName: 'Service Time', width: 250},
        {field: 'serviceorigin', headerName: 'Service Origin', width: 250},
        {field: 'servicedestination', headerName: 'Service Destination', width: 250},
        {field: 'orderdate', headerName: 'Order Date', width: 250},
        {field: 'bookingdeptime', headerName: 'Booking Dep Time', width: 250},
        {field: 'orderid', headerName: 'Order ID', width: 250},
        {field: 'ticketcode', headerName: 'Ticket Code', width: 250},
        {field: 'accomtype', headerName: 'Accom Type', width: 250},
        {
          field: 'action',
          headerName: 'Action',
          width: 330,
          renderCell: ({row}) => {
            return (
              <div className="userListActions">
                <Link
                  to={{
                    pathname: '/contact-edit/' + row.id,
                    state: {listId: params.listId}
                  }}
                >
                  <button className="actionElementButton">
                    <EditOutlined className="actionElementIcon" />
                    {/* <span className="actionButtonText">Edit</span> */}
                  </button>
                </Link>
                <DeleteOutlined
                  className="userListDelete"
                  onClick={() => handleDelete(row.id, row.first_name + ' ' + row.last_name)}
                />
              </div>
            )
          }
        }
      ]
      break
  }

  return (
    <div>
      <h5>{listInfo.name}</h5>
      <p>List Type : {listInfo.type}</p>
      <Breadcrumbs aria-label="breadcrumb" className="mb-5">
        <Link to="/contacts"> Contact Lists </Link>
        <Typography color="text.primary">View List</Typography>
      </Breadcrumbs>
      <div className="text-end my-2">
        <button
          onClick={() => {
            history.push('/newContact', {listId: params.listId, type: listInfo.type})
          }}
          className="btn btn-primary text-white br-10"
        >
          Add Contact
        </button>
        <button
          onClick={() => {
            history.push('/contact/import', {listId: params.listId, type: listInfo.type})
          }}
          className="btn btn-primary text-white br-10 ml-15"
        >
          Import Contact
        </button>
      </div>
      <DataGrid
        rows={list}
        columns={contactColumns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
      />
    </div>
  )
}
