import React, {useState, useEffect} from 'react'
import './settings.css'
import {Grid} from '@mui/material'
import Switch from '@material-ui/core/Switch'

import axios from '../../../helpers/request'
import {useAppState} from '../../../state'
import {ckeditorConfig} from '../../common/ckeditorConfig'

export default function Settings() {
  const {user: loggedUser, refreshUserDetails} = useAppState()
  const companyId = loggedUser.added_by ? loggedUser.added_by : loggedUser.id
  const [mailHeader, setMailHeader] = useState('')
  const [mailFooter, setMailFooter] = useState('')
  const [smsFooter, setSmsFooter] = useState(false)
  const [headerEmailEditor, setHeaderEmailEditor] = useState('')
  const [footerEmailEditor, setFooterEmailEditor] = useState('')

  const [allSwitches, setAllSwitches] = useState({
    mailHeaderActive: false,
    mailFooterActive: false,
    smsFooterActive: false,
    emailTemplateEditEnable: false,
    smsTemplateEditEnable: false,
    enableContacts: false
  })

  const loadCKEditor = () => {
    if (window && document) {
      if (headerEmailEditor === '') {
        window.CKEDITOR.ClassicEditor.create(document.getElementById('header-super-ckeditor'), ckeditorConfig)
          .then(editor => {
            window.editor = editor
            setHeaderEmailEditor(editor)
          })
          .catch(error => {
            loadCKEditor()
          })
      }

      if (footerEmailEditor === '') {
        window.CKEDITOR.ClassicEditor.create(document.getElementById('footer-super-ckeditor'), ckeditorConfig)
          .then(editor => {
            window.editor = editor
            setFooterEmailEditor(editor)
          })
          .catch(error => {
            loadCKEditor()
          })
      }
    }
  }

  const getSettingDetails = () => {
    axios.get(`/get-settings/${companyId}`).then(async response => {
      if (response.status === 200 && response.data) {
        let settings = response.data
        setMailHeader(settings.default_mail_header)
        setMailFooter(settings.default_mail_footer)
        setSmsFooter(settings.default_sms_footer)
        setAllSwitches({
          mailHeaderActive: settings.default_mail_header_active ? true : false,
          mailFooterActive: settings.default_mail_footer_active ? true : false,
          smsFooterActive: settings.default_sms_footer_active ? true : false,
          emailTemplateEditEnable: settings.enable_email_template_edit ? true : false,
          smsTemplateEditEnable: settings.enable_sms_template_edit ? true : false,
          enableContacts: settings.enable_contacts ? true : false
        })
        loadCKEditor()
      } else {
        throw new Error(response?.error)
      }
    })
  }

  useEffect(() => {
    getSettingDetails()
  }, [])

  if (headerEmailEditor !== '') {
    headerEmailEditor.setData(mailHeader)
  }

  if (footerEmailEditor !== '') {
    footerEmailEditor.setData(mailFooter)
  }

  const getMailDataFromEditor = data => {
    let ckcontent = ''
    if (data === 'header' && allSwitches.mailHeaderActive) {
      ckcontent = document.getElementsByClassName('ck-content')[0].innerHTML
    } else if (data === 'footer' && allSwitches.mailFooterActive) {
      ckcontent = document.getElementsByClassName('ck-content')[1].innerHTML
    }

    ckcontent = new DOMParser().parseFromString(ckcontent, 'text/html')
    ckcontent.querySelectorAll('.ck-widget__resizer').forEach(el => el.remove())
    ckcontent.querySelectorAll('.ck-reset_all').forEach(el => el.remove())
    ckcontent.querySelectorAll('.ck-widget__selection-handle').forEach(el => el.remove())
    ckcontent.querySelectorAll('.ck-fake-selection-container').forEach(el => el.remove())

    return ckcontent.documentElement.innerHTML
  }

  const handleSubmit = () => {
    axios
      .post('/update-settings', {
        mailHeaderActive: allSwitches.mailHeaderActive,
        mailFooterActive: allSwitches.mailFooterActive,
        smsFooterActive: allSwitches.smsFooterActive,
        emailTemplateEditEnable: allSwitches.emailTemplateEditEnable,
        smsTemplateEditEnable: allSwitches.smsTemplateEditEnable,
        mailHeader: allSwitches.mailHeaderActive ? getMailDataFromEditor('header') : '',
        mailFooter: allSwitches.mailFooterActive ? getMailDataFromEditor('footer') : '',
        smsFooter: allSwitches.smsFooterActive ? smsFooter : '',
        enableContacts: allSwitches.enableContacts,
        companyId
      })
      .then(function (response) {
        refreshUserDetails()
        alert(`Settings update successfully`)
      })
      .catch(function (error) {
        console.log(error)
        alert('Unable to save settings')
      })
  }

  const handleChangeSwitch = event => {
    const {name, checked} = event.target
    setAllSwitches(prevCampaign => ({
      ...prevCampaign,
      [name]: checked
    }))
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid container item xs={12} md={7} lg={7} className="mt-25 mb--25">
          <p className="label-p">
            Default Email Header
            <span className="form-label">
              <Switch
                checked={allSwitches.mailHeaderActive ? 'checked' : ''}
                onChange={handleChangeSwitch}
                name="mailHeaderActive"
              />
            </span>
          </p>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={7}
          lg={7}
          className={allSwitches.mailHeaderActive ? 'pb-25 mail-header-grid' : 'pb-25 mail-header-grid hide-grid'}
        >
          <div id="header-super-ckeditor"></div>
        </Grid>

        <Grid container item xs={12} md={7} lg={7} className="mb--25">
          <p className="label-p">
            Default Email Footer
            <span className="form-label">
              <Switch
                checked={allSwitches.mailFooterActive ? 'checked' : ''}
                onChange={handleChangeSwitch}
                name="mailFooterActive"
              />
            </span>
          </p>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={7}
          lg={7}
          className={allSwitches.mailFooterActive ? 'pb-25 mail-footer-grid' : 'pb-25 mail-footer-grid hide-grid'}
        >
          <div id="footer-super-ckeditor"></div>
        </Grid>

        <Grid container item xs={12} md={7} lg={7} className="mb--25">
          <p className="label-p">
            Default SMS Footer
            <span className="form-label">
              <Switch
                checked={allSwitches.smsFooterActive ? 'checked' : ''}
                onChange={handleChangeSwitch}
                name="smsFooterActive"
              />
            </span>
          </p>
        </Grid>
        {allSwitches.smsFooterActive ? (
          <Grid container item xs={12} md={7} lg={7} className="pb-25  sms-footer-grid">
            <textarea className="sms-body" value={smsFooter} onChange={e => setSmsFooter(e.target.value)}></textarea>
          </Grid>
        ) : (
          ''
        )}

        <Grid container item xs={12} md={7} lg={7} className="mb--25">
          <p className="label-p">
            Enable Email Template Editing
            <span className="form-label">
              <Switch
                checked={allSwitches.emailTemplateEditEnable ? 'checked' : ''}
                onChange={handleChangeSwitch}
                name="emailTemplateEditEnable"
              />
            </span>
          </p>
        </Grid>

        <Grid container item xs={12} md={7} lg={7} className="mb--25">
          <p className="label-p">
            Enable SMS Template Editing
            <span className="form-label">
              <Switch
                checked={allSwitches.smsTemplateEditEnable ? 'checked' : ''}
                onChange={handleChangeSwitch}
                name="smsTemplateEditEnable"
              />
            </span>
          </p>
        </Grid>

        <Grid container item xs={12} md={7} lg={7} className="mb--25">
          <p className="label-p">
            Enable Contact Module
            <span className="form-label">
              <Switch checked={allSwitches.enableContacts ? 'checked' : ''} onChange={handleChangeSwitch} name="enableContacts" />
            </span>
          </p>
        </Grid>

        <Grid container item xs={12} md={7} lg={7}>
          <div className="templateButtonContainer flex-row-reverse">
            <button className="sendNowButton" onClick={handleSubmit}>
              Update
            </button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
