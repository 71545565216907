import './overview.css'

import {useState, useEffect} from 'react'
import axios from '../../../helpers/request'

import SMSicon from '../../../components/images/smsicon.svg'
import Emailicon from '../../../components/images/emailicon.svg'

import CountUp from 'react-countup'
import {CircularProgressbarWithChildren} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import {Grid} from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from 'chart.js'

import {Bar, Line} from 'react-chartjs-2'
import {useAppState} from '../../../state'
import {DateFilterComponent} from '../DateFilter/DateFilterComponent'
import CustomPopup from '../../Popup/CustomPopup'

import Cookies from 'universal-cookie'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Swal from 'sweetalert2'
import CategoryChart from './components/CategoryChart'

ChartJS.register(PointElement, LineElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

let allMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
let labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const options = {
  plugins: {
    legend: {
      position: 'bottom'
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: false
    }
  },
  redraw: true
}

const defaultDataset = {
  labels,
  datasets: [
    {
      label: 'Email',
      data: [],
      backgroundColor: '#52ace9',
      barThickness: 30,
      borderRadius: 5
    },
    {
      label: 'SMS ',
      data: [],
      backgroundColor: '#da524c',
      barThickness: 35,
      borderRadius: 5
    }
  ]
}

const defaultDataset2 = {
  labels: [],
  datasets: [
    {
      label: 'Sent',
      data: [],
      backgroundColor: '#52ace9',
      barThickness: 30,
      borderRadius: 3
    },
    {
      label: 'Fail ',
      data: [],
      backgroundColor: '#da524c',
      barThickness: 30,
      borderRadius: 3
    }
  ]
}

const byDayOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom'
    }
  },
  redraw: true
}

const getBarDataSets = data => {
  const counts = Object.entries(data)
  return [
    {
      label: 'Sent',
      data: counts.map(([_, d]) => d.success),
      backgroundColor: '#52ace9',
      barThickness: 12,
      borderRadius: 3
    },
    {
      label: 'Fail',
      data: counts.map(([_, d]) => d.failed),
      backgroundColor: '#da524c',
      barThickness: 12,
      borderRadius: 3
    }
  ]
}

const generateRandomColor = () => {
  let maxVal = 0xffffff // 16777215
  let randomNumber = Math.random() * maxVal
  randomNumber = Math.floor(randomNumber)
  randomNumber = randomNumber.toString(16)
  let randColor = randomNumber.padStart(6, 0)
  return `#${randColor.toUpperCase()}`
}

const reduceCb = (acc, {label, status, counts}) => {
  if (!acc[label]) acc[label] = {success: 0, failed: 0}
  acc[label][status] = counts
  return acc
}

export default function Overview() {
  let defaultSmsEmailstatusRate = {
    sms: {
      success: 0,
      fail: 0
    },
    email: {
      success: 0,
      fail: 0
    }
  }
  const {user: loggedUser} = useAppState()
  const [data, setData] = useState({TotalEmails: 0, TotalMessages: 0, TotalFailedLast7: 0, TotalFailed: 0})
  const [additionalData, setAdditionalData] = useState({
    locations: defaultDataset2,
    senders: defaultDataset2,
    routes: defaultDataset2,
    countries: defaultDataset2,
    types: defaultDataset2,
    dates: defaultDataset2
  })
  const [smsStatusRateData, setSmsStatusRateData] = useState(defaultSmsEmailstatusRate.sms)
  const [emailStatusRateData, setEmailStatusRateData] = useState(defaultSmsEmailstatusRate.email)
  const [barCartData, setBarCartData] = useState(defaultDataset)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [titleText, setTitleText] = useState('All Time')
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [chartDetail, setChartDetail] = useState('')
  const [phone, setPhoneNo] = useState('')
  const [routeData, setRouteData] = useState({})
  const [locationData, setLocationsData] = useState({})
  const [isAddPhone, setIsAddPhone] = useState(false)
  const [countryCode, setCountryCode] = useState('')
  const cookies = new Cookies()

  const handleDateSelect = (fromDate, toDate) => {
    setTitleText(fromDate && toDate ? `${new Date(fromDate).toDateString()} - ${new Date(toDate).toDateString()}` : 'All Time')
    getDashboardData()
    getDashboardDataSMS()
    getAllServicesSent() // Pass the dates to getAllServicesSent
    getSmsEmailDeliveryFailureRates() // Pass the dates to getSmsEmailDeliveryFailureRates
  }

  const getDashboardData = () => {
    let url = '/dashboard-analytics-all'
    if (loggedUser.role !== 'superadmin') {
      url = '/dashboard-analytics/' + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
    }
    if (startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`
    }
    axios.get(url).then(async response => {
      if (response.status === 200 && response.data) {
        const {TotalEmails, TotalMessages, TotalFailedLast7, TotalFailed} = response.data
        setData({TotalEmails, TotalMessages, TotalFailedLast7, TotalFailed})
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getRoutesAndLocations = () => {
    return Promise.all([axios.get('/routes'), axios.get('/locations')])
      .then(([response, response2]) => {
        const routesData = response.data.reduce(
          (acc, {rsid, ...rest}) => {
            acc[rsid] = rest
            return acc
          },
          {Other: {route: 'Other'}}
        )
        const locationsData = response2.data.reduce(
          (acc, {BookingLocation, ...rest}) => {
            acc[BookingLocation] = rest
            return acc
          },
          {Other: {LocationDescription: 'Other'}}
        )
        setRouteData(routesData)
        setLocationsData(locationsData)
      })
      .catch(err => {
        console.log('Something went wrong fetching routes and locations')
      })
  }

  const getDashboardDataSMS = () => {
    let url = '/dashboard-analytics-sms-all'
    if (loggedUser.role !== 'superadmin') {
      url = '/dashboard-analytics-sms/' + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
    }
    if (startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`
    }
    axios.get(url).then(async response => {
      if (response.status === 200 && response.data) {
        const {locations, senders, routes, countries, types, dates} = response.data
        const uniqueSenders = senders.reduce((acc, {label, counts}) => {
          acc[label] = counts
          return acc
        }, {})
        const uniqueLocations = locations.reduce(reduceCb, {})
        const uniqueRoutes = routes.reduce(reduceCb, {})
        const uniqueCountries = countries.reduce(reduceCb, {})
        const uniqueTypes = types.reduce(reduceCb, {})
        const uniqueDates = dates.reduce(reduceCb, {})
        setAdditionalData({
          senders: {
            labels: Object.keys(uniqueSenders),
            datasets: [
              {
                data: Object.values(uniqueSenders),
                backgroundColor: Array(Object.keys(uniqueSenders).length)
                  .fill()
                  .map(() => generateRandomColor()),
                borderWidth: 1
              }
            ]
          },
          locations: {
            labels: Object.keys(uniqueLocations).map(location =>
              locationData[location]
                ? `${locationData[location].LocationDescription?.substring(0, 10)}${locationData[location].LocationDescription?.length > 10 ? '...' : ''}`
                : location
            ),
            datasets: getBarDataSets(uniqueLocations)
          },
          routes: {
            labels: Object.keys(uniqueRoutes).map(route =>
              routeData[route]
                ? `${routeData[route].route?.substring(0, 10)}${routeData[route].route?.length > 10 ? '...' : ''}`
                : route
            ),
            datasets: getBarDataSets(uniqueRoutes)
          },
          countries: {labels: Object.keys(uniqueCountries), datasets: getBarDataSets(uniqueCountries)},
          types: {labels: Object.keys(uniqueTypes), datasets: getBarDataSets(uniqueTypes)},
          dates: {
            labels: Object.keys(uniqueDates).map(v => v.substring(0, 10)),
            datasets: [
              {
                label: 'Sent',
                data: Object.entries(uniqueDates).map(([_, d]) => d.success),
                borderColor: '#52ace9'
              },
              {
                label: 'Fail',
                data: Object.entries(uniqueDates).map(([_, d]) => d.failed),
                borderColor: '#da524c'
              }
            ]
          }
        })
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getAllServicesSent = () => {
    let url = '/dashboard-services-sent-all'
    if (loggedUser.role !== 'superadmin') {
      url = `/dashboard-services-sent/${loggedUser.added_by ? loggedUser.added_by : loggedUser.id}`
      if (startDate && endDate) {
        url += `?startDate=${startDate}&endDate=${endDate}`
      }
    }
    axios.get(url).then(async response => {
      if (response.status === 200 && response.data) {
        let tempLabels = response.data.map(data => allMonths[data.sent_month - 1])
        let sentMonths = [...new Set(response.data.map(data => data.sent_month))]
        let firstIndexMonth = tempLabels[0]
        let lastIndexMonth = tempLabels.length.length > 1 ? tempLabels[tempLabels.length - 1] : 0
        labels = [...new Set(tempLabels)]

        let smsData = response.data.filter(data => data.type === 0)
        let emailData = response.data.filter(data => data.type === 1)

        let smsSentMonth = smsData.map(data => data.sent_month)
        let emailSentMonth = emailData.map(data => data.sent_month)

        let smsCount = smsData.map(data => data.count)
        let emailCount = emailData.map(data => data.count)

        sentMonths.map((month, index) => {
          if (index === 0) {
            if (sentMonths[index] === smsSentMonth[index]) {
              smsCount.unshift(0)
            }
            if (sentMonths[index] === emailSentMonth[index]) {
              emailCount.unshift(0)
            }
          } else {
            if (!smsSentMonth.includes(month)) {
              smsCount.splice(index, 0, 0)
            }
            if (!emailSentMonth.includes(month)) {
              emailCount.splice(index, 0, 0)
            }
          }
        })

        if (firstIndexMonth === lastIndexMonth) {
          sentMonths.push(sentMonths[0])
          labels.push(lastIndexMonth)
        }

        smsData = smsData.map(data => data.count)
        emailData = emailData.map(data => data.count)

        const tempchartData = {
          labels,
          datasets: [
            {
              label: 'Email',
              data: emailData,
              backgroundColor: '#52ace9',
              barThickness: 30,
              borderRadius: 5
            },
            {
              label: 'SMS ',
              data: smsData,
              backgroundColor: '#da524c',
              barThickness: 35,
              borderRadius: 5
            }
          ]
        }
        setBarCartData(tempchartData)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getSmsEmailDeliveryFailureRates = () => {
    let smsUrl = '/dashboard-sms-delivery-failure-rate'
    let emailUrl = '/dashboard-email-delivery-failure-rate'

    if (loggedUser.role !== 'superadmin') {
      smsUrl = '/company-dashboard-sms-delivery-failure-rate/' + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
      emailUrl = '/company-dashboard-email-delivery-failure-rate/' + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
      if (startDate && endDate) {
        smsUrl += `?startDate=${startDate}&endDate=${endDate}`
        emailUrl += `?startDate=${startDate}&endDate=${endDate}`
      }
    }

    // Make parallel API calls
    Promise.all([axios.get(smsUrl), axios.get(emailUrl)])
      .then(([smsResponse, emailResponse]) => {
        if (smsResponse.status === 200 && smsResponse.data && emailResponse.status === 200 && emailResponse.data) {
          // SMS data processing
          let smsData = smsResponse.data
          setSmsStatusRateData({success: smsData.successPercentage, fail: smsData.failPercentage})

          // Email data processing
          let emailData = emailResponse.data
          setEmailStatusRateData({success: emailData.successPercentage, fail: emailData.failPercentage})

          console.log('SMS Fail Data', smsData.failData) // Optionally use fail data if needed
        } else {
          throw new Error(smsResponse?.error || emailResponse?.error)
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }
  const closeNumberPopUp = async () => {
    setIsOpenPopup(false)
    const response = await axios.put(process.env.REACT_APP_API_BASEURL + '/update-phone-status', {
      phone: 'false',
      email: loggedUser?.email,
      isAdd: false
    })

    if (response.status === 200) {
      cookies.set('cs_isPopUp', response?.data?.isOpenPopup)
    }
  }

  const handleUpdateNumber = async () => {
    const checkPhone = phone.replace(countryCode, '')
    if (phone !== '' && checkPhone !== '') {
      const response = await axios.put(process.env.REACT_APP_API_BASEURL + '/update-phone-status', {
        phone: phone,
        email: loggedUser?.email,
        isAdd: true
      })
      if (response?.status === 200) {
        cookies.remove('cs_isPopUp')
        setIsAddPhone(false)
        setIsOpenPopup(false)
      }
    } else {
      Swal.fire({
        title: 'Please enter you phone number',
        confirmButtonColor: '#3085d6'
      })
    }
  }

  useEffect(() => {
    getRoutesAndLocations()
    getDashboardData()
    //getDashboardDataWhatsapp()
    getAllServicesSent()
    getSmsEmailDeliveryFailureRates()
    //createWPChannel()
    const popUp = cookies.get('cs_isPopUp')
    if (popUp === 'true') {
      setIsOpenPopup(true)
    }
  }, [])

  useEffect(() => {
    getDashboardDataSMS()
  }, [routeData, locationData])

  const handleChartClick = category => {
    setChartDetail(!chartDetail ? category : '')
  }

  return (
    <div>
      <DateFilterComponent onDateSelect={handleDateSelect} setStartDate={setStartDate} setEndDate={setEndDate} />
      <Grid
        container
        justifyContent="center"
        spacing={4}
        className="dashboardboxsmallcontainer"
        columns={{xs: 12, sm: 12, md: 12}}
      >
        <Grid item xs={12} sm={5} md className="dashboardboxsmall">
          <img src={SMSicon} width="300" />
          <h2>SMS</h2>
          <p>Total SMS Sent</p>
          <h3>
            <CountUp end={data.TotalMessages} />
          </h3>
        </Grid>
        <Grid item xs={12} sm={5} md className="dashboardboxsmall">
          <img src={SMSicon} width="300" />
          <h2>SMS</h2>
          <p>Total SMS Failed</p>
          <h3>
            <CountUp end={data.TotalFailed} />
          </h3>
        </Grid>
        <Grid item xs={12} sm={5} md className="dashboardboxsmall">
          <img src={SMSicon} width="300" />
          <h2>SMS</h2>
          <p>Failed(Last 7 days)</p>
          <h3>
            <CountUp end={data.TotalFailedLast7} />
          </h3>
        </Grid>
        <Grid item xs={12} sm={5} md className="dashboardboxsmall">
          <img src={Emailicon} width="300" />
          <h2>Email</h2>
          <p>Total Emails Sent</p>
          <h3>
            <CountUp end={data.TotalEmails} />
          </h3>
        </Grid>
      </Grid>
      {!chartDetail ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg className="mb-5">
              <div className="completion-container">
                <span className="response-block"></span>
                <span className="title">SMS Delivery and Failure Rate ({titleText})</span>
              </div>
              <div className="completion-response-chart mt-2">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={6} className="chart-content">
                    <p className="chart-title title">Delivered</p>
                    <CircularProgressbarWithChildren
                      strokeWidth={5}
                      text={(smsStatusRateData.success || 0) + '%'}
                      styles={{
                        root: {
                          height: '250px',
                          width: 'auto'
                        },
                        path: {
                          stroke: '#27AE60'
                        },
                        trail: {
                          stroke: '#9bedbd'
                        }
                      }}
                      value={smsStatusRateData.success}
                    ></CircularProgressbarWithChildren>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} className="chart-content">
                    <p className="chart-title title">Failed</p>
                    <CircularProgressbarWithChildren
                      strokeWidth={5}
                      text={(smsStatusRateData.fail || 0) + '%'}
                      styles={{
                        root: {
                          height: '250px',
                          width: 'auto'
                        },
                        path: {
                          stroke: '#ee5864'
                        },
                        trail: {
                          stroke: '#f8b9bd'
                        }
                      }}
                      value={smsStatusRateData.fail}
                    ></CircularProgressbarWithChildren>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg className="mb-5">
              <div className="completion-container">
                <span className="response-block"></span>
                <span className="title">Email Delivery and Failure Rate ({titleText})</span>
              </div>
              <div className="completion-response-chart mt-2">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={6} className="chart-content">
                    <p className="chart-title title">Delivered</p>
                    <CircularProgressbarWithChildren
                      strokeWidth={5}
                      text={(emailStatusRateData.success || 0) + '%'}
                      styles={{
                        root: {
                          height: '250px',
                          width: 'auto'
                        },
                        path: {
                          stroke: '#27AE60'
                        },
                        trail: {
                          stroke: '#9bedbd'
                        }
                      }}
                      value={emailStatusRateData.success}
                    ></CircularProgressbarWithChildren>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} className="chart-content">
                    <p className="chart-title title">Failed</p>
                    <CircularProgressbarWithChildren
                      strokeWidth={5}
                      text={(emailStatusRateData.fail || 0) + '%'}
                      styles={{
                        root: {
                          height: '250px',
                          width: 'auto'
                        },
                        path: {
                          stroke: '#ee5864'
                        },
                        trail: {
                          stroke: '#f8b9bd'
                        }
                      }}
                      value={emailStatusRateData.fail}
                    ></CircularProgressbarWithChildren>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg className="dashboardboxsmallmed">
              <div className="completion-container">
                <span className="response-block"></span>
                <span className="title">Campaign Send Rates ({titleText}) </span>
              </div>
              <Bar className="bar-chart completion-response-chart" options={options} data={barCartData} redraw={false} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg className="dashboardboxsmallmed">
              <div className="completion-container">
                <span className="response-block"></span>
                <span className="title">Sent and Failed by Day </span>
              </div>
              <Line
                className="bar-chart completion-response-chart"
                options={{...byDayOptions, onClick: () => handleChartClick('date')}}
                data={additionalData.dates}
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} spacing={{xs: 2, md: 4}} columns={{xs: 4, md: 4, lg: 8}}>
            {['countries', 'locations', 'types', 'routes', 'senders'].map(category => (
              <CategoryChart
                category={category}
                data={additionalData[category]}
                handleChartClick={handleChartClick}
                type={category === 'senders' ? 'Pie' : null}
              />
            ))}
          </Grid>
        </>
      ) : (
        <Grid container rowSpacing={1} spacing={{xs: 2, md: 4}} columns={{xs: 4}}>
          <CategoryChart
            type={chartDetail === 'senders' ? 'Pie' : 'Bar'}
            fullView={true}
            category={chartDetail}
            data={additionalData[chartDetail]}
            handleChartClick={handleChartClick}
          />
        </Grid>
      )}

      <CustomPopup trigger={isOpenPopup} setTrigger={() => closeNumberPopUp()}>
        <div className="phone-msg">
          <div className="msg-text">Attention Users: Enable Two-Factor Authentication (2FA) Today!</div>
          <div className="msg-sub-text">
            Protect your account with an extra layer of security! Our Two-Factor Authentication (2FA) supports mobile numbers and
            is incredibly easy to use. Update your mobile number now to keep your account safe and secure.
          </div>
          <div className="main-phone">
            <button className="btn-add-phone btn btn-primary text-white br-10" onClick={() => setIsAddPhone(!isAddPhone)}>
              Add Phone number
            </button>
          </div>
        </div>
      </CustomPopup>
      <CustomPopup trigger={isAddPhone} setTrigger={() => setIsAddPhone(!isAddPhone)}>
        <div>
          <div className="form-main">
            <form>
              <div className="phone-form">
                <label>Phone No</label>
                <PhoneInput
                  inputStyle={{
                    width: '600px',
                    height: '50px'
                  }}
                  country={'ie'}
                  value={phone}
                  onChange={(data, country) => {
                    setPhoneNo(data)
                    setCountryCode(country?.dialCode)
                  }}
                />
                <div className="phone-btn-add">
                  <button className="btn btn-primary text-white br-10" type="button" onClick={() => handleUpdateNumber()}>
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomPopup>
    </div>
  )
}
